define([
  './playhead',
  './delegate/comscore/label-keys',
  './schedule',
  './media'
], function(Playhead, CSKeys, Schedule) {

  'use strict';

  var NO_BROADCAST = 'no-broadcast';
  var UPDATE_INTERVAL = 1000;
  var TIMESTAMP_DRIFT_TOLERANCE = 1500;

  /**
   * @param {Object} playerDelegate
   * @param {Media} media the current media item
   * @param {EchoClient} echoClient
   * @param {Obect} environment
   * @param {Boolean} essEnabled
   * @constructor
   */
  function LiveBroker(
    playerDelegate, media, echoClient, environment, essEnabled
  ) {

    this._playerDelegate = playerDelegate;
    this._playhead = new Playhead();
    this._isStarted = false;
    this._interval = null;
    this._echoClient = echoClient;
    this._broadcast = NO_BROADCAST;
    this._playerDelegateTimestamp = 0;
    this._previousPosition = 0;
    this._playingTime = 0;
    this._media = media;
    this._environment = environment;
    this._essEnabled = essEnabled;
    this._hb3NotSentYet = true;
    this._hb5NotSentYet = true;

    this._isRecievingPlayerTimestamp = false;
    this._updateTime = 0;

    if (this._essEnabled) {
      this._schedule = new Schedule(media, environment, echoClient);
    }
  }

  /**
   * Start up the broker
   */
  LiveBroker.prototype.start = function() {

    var self = this;

    if (this._isStarted) {
      return;
    }

    this._interval = setInterval(function() {
      self._update();
    }, UPDATE_INTERVAL);

    this._isStarted = true;

    if (!this._isRecievingPlayerTimestamp) {
      this._playhead.start();
    }
  };

  /**
   * Stop the broker
   */
  LiveBroker.prototype.stop = function() {
    clearInterval(this._interval);
    this._playhead.stop();
    this._isStarted = false;
  };

  /**
   * Check to see if the broadcast has changed based on the current player time.
   *
   * @private
   */
  LiveBroker.prototype._update = function() {
    var playerDelegateTimestamp = parseInt(this._playerDelegate.getTimestamp(), 10);
    var playerDelegateTimestampHasChanged = false;
    var playerDelegateTimestampIsValid = false;
    var hasRealignedTimestampToPlayerDelegate = false;

    var timestamp = this.getTimestamp();
    var timestampDelta;
    var now = new Date();
    now = now.getTime();
    var updateDelta = now - this._updateTime;
    this._updateTime = now;

    if ((typeof playerDelegateTimestamp === 'number') &&
        playerDelegateTimestamp > 0
    ) {
      playerDelegateTimestampIsValid = true;
      this._isRecievingPlayerTimestamp = true;

      if (this._playerDelegateTimestamp !== playerDelegateTimestamp) {
        playerDelegateTimestampHasChanged = true;
        this._echoClient.addLabel(CSKeys.MEDIA_TIMESTAMP, playerDelegateTimestamp);
      }

      // Determine if our internal representation of time (via this.getTimestamp()
      // or this._playerDelegateTimestamp + this._playhead.getPosition()) is aligned
      // with the value recieved from the player delegate.
      timestampDelta = playerDelegateTimestamp - timestamp;

      if (
        playerDelegateTimestampIsValid &&
        (
          this._playerDelegateTimestamp === 0 ||
          timestampDelta < (0 - updateDelta - TIMESTAMP_DRIFT_TOLERANCE) ||
          timestampDelta > (updateDelta + TIMESTAMP_DRIFT_TOLERANCE)
        )
      ) {
        hasRealignedTimestampToPlayerDelegate = true;
        this._playerDelegateTimestamp = playerDelegateTimestamp;
        this._playingTime += this._playhead.getPosition();
        this._restartPlayhead();
        timestamp = this.getTimestamp();
      }
    }

    if (!hasRealignedTimestampToPlayerDelegate) {
      if (
        (this._playerDelegateTimestamp !== 0 && !playerDelegateTimestampHasChanged) ||
        (this._isRecievingPlayerTimestamp && !playerDelegateTimestampIsValid)
      ) {
        this._playhead.stop();
      } else {
        this._playhead.start();
      }
    }

    // Now we have determined the current timestamp we need to check where
    // we are in the schedule and update the media item if necessary.
    var hasUpdatedMedia = this._updateSchedule(timestamp);

    // if we have created a new media item we need to reset playing time
    // playhead and Echo heartbeat flags so the heartbeats are sent again
    // for the new media item
    if (hasUpdatedMedia) {
      this._playingTime = 0;
      this._playerDelegateTimestamp = playerDelegateTimestamp;
      this._restartPlayhead();
      this._hb3NotSentYet = true;
      this._hb5NotSentYet = true;
    } else if (playerDelegateTimestampHasChanged) {
      this._echoClient.releaseSuppressedPlay();
    }

    this._updateHeartbeats();

    this._previousPosition = this.getPosition();

  };

  /**
   * Check to see if the broadcast has changed based on the
   * provided timestamp.
   *
   * @param {Number} timestamp  The timestamp to compare with the schedule
   *
   * @private
   */
  LiveBroker.prototype._updateSchedule = function(timestamp) {
    var broadcast;
    var broadcastTime;
    var clipLength;
    var media;
    var hasUpdatedMedia = false;
    var masterbrand;

    if (this._essEnabled && this._schedule.hasData() && timestamp > 0) {

      broadcast = this._schedule.getBroadcast(timestamp);

      media = this._media.getClone();
      media.setServiceId(this._schedule.serviceId);

      if (!broadcast && this._broadcast !== NO_BROADCAST) {
        // we were previously in a broadcast and have now gone into dead space
        this._broadcast = NO_BROADCAST;
        this._updateMedia(media);
        hasUpdatedMedia = true;
      } else if (
        broadcast && (
          this._broadcast === NO_BROADCAST ||
          broadcast.version.id !== this._broadcast.version.id
        )
      ) {
        /**
         * if we don't already have a broadcast, or if the cached broadcast is
         * different i.e. crossed boundary.
         */

        this._broadcast = broadcast;
        broadcastTime = broadcast.published_time;
        clipLength = broadcastTime.end - broadcastTime.start;

        media.setEssEnriched(true);
        media.setVersionId(broadcast.version.id);
        media.setLength(clipLength);

        if (broadcast.masterbrand && broadcast.masterbrand.id) {
          masterbrand = broadcast.masterbrand.id;
        }

        /**
          * if we don't have a masterbrand from ESS call setProducerByMasterbrand with undefined
          * this will set the media producer to 0
        */
        media.setProducerByMasterbrand(masterbrand);

        if (broadcast.episode && broadcast.episode.id) {
          media.setEpisodeId(broadcast.episode.id);
        }

        this._updateMedia(media);
        hasUpdatedMedia = true;
      }
    }

    return hasUpdatedMedia;
  };

  /**
   * Send heartbeat events if necessary.
   *
   * @private
   */
  LiveBroker.prototype._updateHeartbeats = function() {

    var playingTime;
    var position;

    if (this._broadcast === NO_BROADCAST || (!this._hb3NotSentYet && !this._hb5NotSentYet)) {
      return;
    }

    playingTime = this._playingTime + this._playhead.getPosition();
    position = this.getPosition();

    // send 3 second heartbeat
    if (playingTime >= 3000 && this._hb3NotSentYet) {
      this._echoClient.avUserActionEvent('echo_hb', 'echo_hb_3', position);
      this._hb3NotSentYet = false;
    }

    // send 5 second heartbeat
    if (playingTime >= 5000 && this._hb5NotSentYet) {
      this._echoClient.avUserActionEvent('echo_hb', 'echo_hb_5', position);
      this._hb5NotSentYet = false;
    }
  };

  /**
   * Broadcast the live media update.
   *
   * @param {Media} media a new media item
   * @private
   */
  LiveBroker.prototype._updateMedia = function(media) {

    var position = this.getPosition();

    this._echoClient.liveMediaUpdate(
      media,
      position,
      this._previousPosition
    );

    this._previousPosition = position;
  };

  /**
   * Convenience method for reseting and playing the playhead.
   *
   * @private
   */
  LiveBroker.prototype._restartPlayhead = function() {
    this._playhead.reset();
    this._playhead.start();
  };

  /**
   * Represents the true position a player is at within a piece of media.
   *
   * @returns {Number}
   */
  LiveBroker.prototype.getPosition = function() {

    var position;

    if (this._broadcast && this._broadcast !== NO_BROADCAST) {
      position = (
        this._playerDelegateTimestamp - this._broadcast.published_time.start
      ) + this._playhead.getPosition();
    } else {
      position = this._playhead.getPosition();
    }

    return position;
  };

  /**
   * Represents the timestamp a player is at within a piece of media.
   *
   * @returns {Number}
   */
  LiveBroker.prototype.getTimestamp = function() {
    return this._playerDelegateTimestamp + this._playhead.getPosition();
  };

  return LiveBroker;

});
