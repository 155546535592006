define(function() {
  'use strict';

  var DEBUG = require('../../util/debug');

  var Promise = require('es6-promise').Promise;

  function OrbitVariables() {}

  OrbitVariables.get = function() {
    if (window && window.bbcpage) {

      // Allows the passed in method to be called, even though it might not exist on the window.bbcpage object
      // Call the method if it exists, otherwise return a resolved promise instead
      var wrapMethod = function(methodName) {
        return window.bbcpage[methodName] && typeof window.bbcpage[methodName] === 'function' ? window.bbcpage[methodName]() : Promise.resolve(null);
      };

      // Call all of these async methods in parallel
      return Promise.all([
        'getDestination',
        'getProducer',
        'getSection',
        'getContentId',
        'getContentType',
        'getLanguage',
        'getAdditionalProperties',
        'getName'
      ]

        // Handle the fact that the async mathods may not exist on the window.bbcpage object
        .map(function(methodName) {
          return wrapMethod(methodName);
        }))

        // Build the returned result object
        .then(function (orbitVars) {
          //remove any encoding applied by orbit
          orbitVars = OrbitVariables._decodeFromOrbit(orbitVars);
          DEBUG.info('Got Orbit Variables: ' + JSON.stringify(orbitVars));
          return {
            destination: orbitVars[0],
            producer: orbitVars[1],
            section: orbitVars[2],
            contentId: orbitVars[3],
            contentType: orbitVars[4],
            language: orbitVars[5],
            additionalProperties: orbitVars[6],
            pageName: orbitVars[7]
          };
        });
    } else {
      return Promise.resolve(undefined);
    }
  };

  OrbitVariables.getUser = function() {
    if (window && window.bbcuser) {

      // Allows the passed in method to be called, even though it might not exist on the window.bbcuser object
      // Call the method if it exists, otherwise return a resolved promise instead
      var wrapMethod = function(methodName) {
        return window.bbcuser[methodName] && typeof window.bbcuser[methodName] === 'function' ? window.bbcuser[methodName]() : Promise.resolve(null);
      };

      // Call all of these async methods in parallel
      return Promise.all([
        'getHashedId',
        'isSignedIn'
      ]

        // Handle the fact that the async methods may not exist on the window.bbcuser object
        .map(function(methodName) {
          return wrapMethod(methodName);
        }))

        // Build the returned result object
        .then(function (userVars) {
          DEBUG.info('Got Orbit User Variables: ' + JSON.stringify(userVars));
          return {
            hashedId: userVars[0],
            isSignedIn: userVars[1]
          };
        });
    } else {
      return Promise.resolve(undefined);
    }
  };

  /*
  * Forward slashes in variables provided by Orbit are encoded.
  * This decodes them. Further characters to be added as we find them.
  */
  OrbitVariables._decodeFromOrbit = function (orbitVars) {
    var keys = Object.keys(orbitVars);
    for (var i = 0; i < keys.length; i++) {
      if (orbitVars[keys[i]] && typeof orbitVars[keys[i]] !== 'object') {
        var encodedForwardSlash = new RegExp('\&\#x2F;', 'g');
        orbitVars[keys[i]] = orbitVars[keys[i]].replace(encodedForwardSlash, '/');
      } else if (orbitVars[keys[i]] && typeof orbitVars[keys[i]] === 'object') {
        orbitVars[keys[i]] = OrbitVariables._decodeFromOrbit(orbitVars[keys[i]]);
      }
    }

    return orbitVars;
  };

  return OrbitVariables;
});
