/**
 * A class to allow for varied enrionments.
 * An instance of this can be passed into EchoClient constructor.
 * Default values are for a standard web environment.
 * @exports Echo/Environment
 */
define(function(require) {
  'use strict';

  var LabelCleanser = require('./util/cleansing/label-cleanser');
  var Cookies = require('./util/cookies');
  var Networking = require('./util/networking');

  var orbCookies;
  var orbIdCta;

  // must be initialised as undefined
  var orbIdCtaIsAvailable;
  var orbIdCtaIsLoading = false;
  var orbIdCtaCallbacks = [];

  /**
   * Initialise an Environment object
   * An instance of this can be passed into EchoClient constructor
   * Default values are for a standard web environment. If these are fine
   * for your purposes, then this class does not need to be used and nothing
   * need be passed into `new EchoClient()`
   * @constructor
   */
  function Environment() {
    var screen = window.screen || {};
    var navigator = window.navigator || {};
    var document = window.document || {};

    this._platformRuntimeEnvironment = 'html';
    this._platformOSVersion = '5'; //TODO

    this._screenResolution = (screen.width || '-') +
      'x' + (screen.height || '-');

    this._language = navigator.language || '';
    this._platformName = navigator.platform || '';

    this._charset = document.characterSet || document.defaultCharset || '';
    this._title = document.title || '';
    this._url = document.URL || '';
    this._referrer = document.referrer || '';

    this._deviceName = null; // Dont send if not set

    this._httpGet = null;
    this._executeCrossDomainGet = null;

    this._getCookie = null; // similarly
    this._setCookie = null;

    this._setLocalStorageItem = null;
    this._getLocalStorageItem = null;
  }

  Environment._defaultHttpGet = Networking.httpGet;
  Environment._defaultGetCookie = Cookies.getCookieValueByName;

  Environment.prototype.getPlatformName = function() {
    return this._platformName;
  };

  Environment.prototype.getPlatformRuntimeEnvironment = function() {
    return this._platformRuntimeEnvironment;
  };

  Environment.prototype.getPlatformOSVersion = function() {
    return this._platformOSVersion;
  };

  Environment.prototype.getDeviceName = function() {
    return this._deviceName;
  };

  Environment.prototype.getScreenResolution = function() {
    return this._screenResolution;
  };

  Environment.prototype.getLanguage = function() {
    return this._language;
  };

  Environment.prototype.getHttpGet = function() {
    return this._httpGet;
  };

  Environment.prototype.setExecuteCrossDomainGet = function(executeCrossDomainGet) {
    this._executeCrossDomainGet = executeCrossDomainGet;
  };

  Environment.prototype.getExecuteCrossDomainGet = function() {
    var executeCrossDomainGet;
    if (this._executeCrossDomainGet) {
      executeCrossDomainGet = this._executeCrossDomainGet;
    } else {
      executeCrossDomainGet = Networking.executeCrossDomainGet;
    }

    return executeCrossDomainGet;
  };

  Environment.prototype.getCookieGetter = function() {
    return this._getCookie;
  };

  Environment.prototype.getCookieSetter = function() {
    return this._setCookie;
  };

  Environment.prototype.getCharSet = function() {
    return this._charset;
  };

  Environment.prototype.getTitle = function() {
    return this._title;
  };

  Environment.prototype.getURL = function() {
    return this._url;
  };

  Environment.prototype.getProtocol = function() {
    return this.getURL().toLowerCase().indexOf('http:', 0) !== 0 ? 'https:' : 'http:';
  };

  Environment.prototype.getReferrer = function() {
    return this._referrer;
  };

  Environment.prototype.setSetLocalStorageItem = function(setLocalStorageItem) {
    this._setLocalStorageItem = setLocalStorageItem;
  };

  Environment.prototype.getSetLocalStorageItem = function() {
    return this._setLocalStorageItem;
  };

  Environment.prototype.setGetLocalStorageItem = function(getLocalStorageItem) {
    this._getLocalStorageItem = getLocalStorageItem;
  };

  Environment.prototype.getGetLocalStorageItem = function () {
    return this._getLocalStorageItem;
  };

  /**
   * Set the "Platform Name". If this method is not called the
   * default value will be `window.navigator.platform`
   * @param {string} name
   * @returns {this} `this`
   */
  Environment.prototype.setPlatformName = function(name) {
    this._platformName = name;
    return this;
  };

  /**
   * Set the "Platform Runtime Environment" and the "Platform OS Version".
   * If this method is not called the defaults will be `'html'` and `'5'`
   * @param {string} platformRuntimeEnvironment
   * @param {string} platformOSVersion
   * @returns {this} `this`
   */
  Environment.prototype.setPlatform =
    function(platformRuntimeEnvironment, platformOSVersion) {
    this._platformRuntimeEnvironment = platformRuntimeEnvironment;
    this._platformOSVersion = platformOSVersion;
    return this;
  };

  /**
   * Set the "Device Name". If this method is not called the
   * default value will not be set (and potentially popluated server-side)
   * @param {string} deviceName
   * @returns {this} `this`
   */
  Environment.prototype.setDeviceName = function(deviceName) {
    this._deviceName = deviceName;
    return this;
  };

  /**
   * Set the Screen Resoultion. If this method is not called the
   * default will be `window.screen.availWidth + 'x' +
   * window.screen.availHeight`
   * @param {string} screenResolution
   * @example env.setScreenResolution('200x300');
   */
  Environment.prototype.setScreenResolution = function(screenResolution) {
    this._screenResolution = screenResolution;
    return this;
  };

  /**
   * Set the "device Language (locale)". If this method is not called the
   * default value will be `window.navigator.language`. This should not be
   * confused with the `setContentLanguage()` EchoClient instance method which
   * asks for the language being displayed
   * @param {string} language
   * @returns {this} `this`
   */
  Environment.prototype.setLanguage = function(language) {
    // ECHO-12
    this._language = LabelCleanser.cleanLanguage(language);
    return this;
  };

  /**
   * Set a function to use for httpGet request. If this method is not called
   * AJAX or an `Image` object will be used
   * @param {function} httpGet
   * @returns {this} `this`
   * @example
   * env.setHttpGet(function(url,headers,onSuccess,onError){
   *     var img = new Image();
   *     img.src = url;
   *     if(onSuccess){
   *         img.onload = onSuccess;
   *     }
   *     if(onError){
   *         img.onerror = onError;
   *     }
   * });
   */
  Environment.prototype.setHttpGet = function(httpGet) {
    this._httpGet = httpGet;
    return this;
  };

  /**
   * Set a function to use for getting cookies from the environment.
   * If this method is not called, cookies will be searched for in
   * `document.cookie`
   * @param {function} getCookie
   * @returns {this} `this`
   * @example
   * env.setCookieGetter(function(name){
   *     // get and return the "name" cookie
   * });
   */
  Environment.prototype.setCookieGetter = function(getCookie) {
    this._getCookie = getCookie;
    return this;
  };

  /**
   * Set a function to use for setting cookies.
   * If this method is not called, cookies will be set on `document.cookie`
   * See the example for an example that will work on the web. The passed
   * method should accept all the parameters which the exmaple method does:
   *
   * |Name|Type|Description|
   * |----|----|-----------|
   * |`key`|String|The name of the cookie|
   * |`value`|String|The cookie value|
   * |`path`|String|The path to set the cookie on|
   * |`maxAge`|Integer|The number of milliseconds until the cookie expires|
   *
   * @param {function} setCookie
   * @returns {this} `this`
   * @example
   * env.setCookieSetter(function(key,value,path,maxAge){
   *     var t = new Date();
   *     t.setTime(t.getTime() + maxAge);
   *
   *     document.cookie = key + '=' + value + ';' +
   *                       ' expires=' + t.toUTCString() + ';' +
   *                       ' path=' + path + ';';
   * });
   */
  Environment.prototype.setCookieSetter = function(setCookie) {
    this._setCookie = setCookie;
    return this;
  };

  /**
   * Set the "Character Set". If this method is not called the
   * default value will be `document.characterSet` or, failing that
   * `document.defaultCharset`
   * @param {string} charSet
   * @returns {this} `this`
   */
  Environment.prototype.setCharSet = function(charSet) {
    this._charset = charSet;
    return this;
  };

  /**
   * Set the page title. If this method is not called the
   * default value will be `document.title`
   * @param {string} title
   * @returns {this} `this`
   */
  Environment.prototype.setTitle = function(title) {
    this._title = title;
    return this;
  };

  /**
   * Set the current URL. If this method is not called the
   * default value will be `document.URL`
   * @param {string} url
   * @returns {this} `this`
   */
  Environment.prototype.setURL = function(url) {
    this._url = url;
    return this;
  };

  /**
   * Set the referrer url. If this method is not called the
   * default value will be `document.referrer`
   * @param {string} ref
   * @returns {this} `this`
   */
  Environment.prototype.setReferrer = function(ref) {
    this._referrer = ref;
    return this;
  };

  Environment.setOrbCookies = function(value) {
    orbCookies = value;
    return this;
  };

  Environment.getOrbCookies = function() {
    return orbCookies;
  };

  Environment.getOrbCookiesPolicy = function() {
    if (orbCookies && typeof orbCookies.readPolicy === 'function') {
      return orbCookies.readPolicy();
    }
  };

  Environment.setOrbIdCta = function(value) {
    orbIdCta = value;
    return this;
  };

  Environment.getOrbIdCta = function(callback) {

    var callbackIndex;

    var safeCallback = function(potentialCallback, orbIdCta) {
      if (potentialCallback && typeof potentialCallback === 'function') {
        potentialCallback(orbIdCta);
      }
    };

    if (orbIdCta !== undefined) {
      safeCallback(callback, orbIdCta);
      return;
    } else if (orbIdCtaIsAvailable === false) {
      safeCallback(callback);
      return;
    }

    orbIdCtaCallbacks.push(callback);

    /**
     * We use window.require here rather than the local require (from 'echo.js')
     * as we want to be able to control require behaviour in integration tests.
     */
    var require = window.require;
    if (orbIdCtaIsAvailable === undefined && require) {
      orbIdCtaIsAvailable = typeof require.defined === 'function' &&
        require.defined('idcta/idcta-1');

      // we are looking into the interals of require so be
      // as defensive as possible
      if (
        !orbIdCtaIsAvailable &&
        require.s &&
        require.s.contexts &&
        require.s.contexts._ &&
        require.s.contexts._.config &&
        require.s.contexts._.config.paths &&
        require.s.contexts._.config.paths.idcta
      ) {
        orbIdCtaIsAvailable = true;
      }

    } else {
      orbIdCtaIsAvailable = false;
    }

    // load the idcta is available and not already being loaded
    if (orbIdCtaIsAvailable && !orbIdCtaIsLoading) {
      orbIdCtaIsLoading = true;

      window.require(['idcta/idcta-1'], function(idcta) {
        orbIdCta = idcta;
        for (callbackIndex = 0; callbackIndex < orbIdCtaCallbacks.length; orbIdCtaCallbacks++) {
          if (
            orbIdCtaCallbacks[callbackIndex] &&
            typeof orbIdCtaCallbacks[callbackIndex] === 'function'
          ) {
            safeCallback(orbIdCtaCallbacks[callbackIndex], orbIdCta);
          }
        }
      });
    } else if (orbIdCtaIsAvailable === false) {
      safeCallback(callback);
    }
  };

  return Environment;
});
