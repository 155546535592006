define(function() {
  'use strict';

  var ATILabelKeys = require('./label-keys');
  var LabelCleanser = require('../../util/cleansing/label-cleanser');

  var SEPARATOR_CHAR = '~';

  var EventDefaults = {
    ACTION_NAME: 'unknown',
    CAMPAIGN_ID: 'Page',
    VARIANT: '',
    FORMAT: '',
    GENERAL_PLACEMENT: 'unknown',
    DETAILED_PLACEMENT: '',
    ADVERTISER_ID: '',
    URL: 'unknown'
  };

  var EventType = {
    CLICK: 'click',
    IMPRESSION: 'impression'
  };

  function UserActionEvent() {}

  UserActionEvent.sendClickEvent = function(tag, eventValues) {
    this._sendEvent(tag, eventValues, EventType.CLICK);
  };

  UserActionEvent.sendImpressionEvent = function(tag, eventValues) {
    this._sendEvent(tag, eventValues, EventType.IMPRESSION);
  };

  UserActionEvent._sendEvent = function(tag, eventValues, eventType) {
    if (!eventValues) {
      eventValues = {
        customObject: {}
      };
    }

    if (!eventValues.customObject) {
      eventValues.customObject = {};
    }

    var eventPayload = {
      creation:           this._formatEventValue(UserActionEvent._buildCreation(eventValues)),
      campaignId:         this._formatEventValue(eventValues.customObject[ATILabelKeys.CONTAINER] || EventDefaults.CAMPAIGN_ID),
      variant:            this._formatEventValue(eventValues.customObject[ATILabelKeys.PERSONALISATION] || EventDefaults.VARIANT),
      format:             this._formatEventValue(eventValues.customObject[ATILabelKeys.METADATA] || EventDefaults.FORMAT),
      generalPlacement:   this._formatEventValue(eventValues[ATILabelKeys.BBC_COUNTER_NAME] || EventDefaults.GENERAL_PLACEMENT),
      detailedPlacement:  this._formatEventValue(eventValues.user || EventDefaults.DETAILED_PLACEMENT),
      advertiserId:       this._formatEventValue(eventValues.customObject[ATILabelKeys.SOURCE] || EventDefaults.ADVERTISER_ID),
      url:                this._formatEventValue(eventValues.customObject[ATILabelKeys.RESULT] || EventDefaults.URL)
    };

    var event = {};
    if (eventType === EventType.CLICK) {
      event.click = eventPayload;
    } else {
      event.impression = eventPayload;
    }

    if (tag) {
      tag.publisher.send(event);

      // we can't use tag.dispatch() with publisher due to the keepalive event setting the old page params back on the tag
      // and this causing an issue with the user action event when it dispatches
      // instead we need to manually remove any properties that were passed to the action event
      for (var key in eventValues.customObject) {
        tag.delParam(key);
      }
    }
  };

  UserActionEvent._buildCreation = function(eventValues) {
    var creation = (eventValues && eventValues.actionName) || EventDefaults.ACTION_NAME;
    if (eventValues && eventValues.actionType) {
      creation += (SEPARATOR_CHAR + eventValues.actionType);
    }

    return creation;
  };

  UserActionEvent._formatEventValue = function(value) {
    return '[' + LabelCleanser.cleanCustomVariable(value) + ']';
  };

  return UserActionEvent;
});
