/** START BBC ADDITION **/
define( function(){
/** END BBC ADDITION **/

function SpringStreams(g) {
    this.namespace = "com.kantarmedia.sensors.javascript";
    this.version = "2.4.0";
    var s = "2cnt.net";
    var f = 200;
    var p = 2000;
    var V = "default";
    var K = new Array();
    this.namespace = "com.kma.springstreams";
    this.syncrate = 20;
    this.pausesync = 6;
    this.maxstates = 50;
    var e = true;
    this.nextuid = "";
    var R = {
        "+": "%2B",
        ",": "%2C",
        ";": "%3B",
        "=": "%3D",
        "~": "%7E",
        "*": "%2A",
        "'": "%27",
        "!": "%21",
        "(": "%28",
        ")": "%29"
    };
    var a = this;
    var S;
    D(q());
    if (g) {
        V = g
    }
    A();

    function q() {
        var m = {
            "2cnt.net": {
                syncrates: [3, 7, 10, 10, 10, 10, 10, 60]
            }
        };
        return m
    }

    function D(W) {
        var X;
        if (W) {
            for (var m in W) {
                if (m === s) {
                    X = W[m]
                }
            }
            if (X) {
                F(X.syncrates)
            }
        }
    }

    function F(m) {
        if (m) {
            S = m
        }
    }

    function L() {
        return S
    }
    this.getSyncRates = L;

    function w(Y, W, m) {
        if (a.pageContext === undefined) {
            a.pageContext = a.defaultPageContext
        }
        if (a.pageContext.generateId !== undefined) {
            a.pageContext.generateId()
        }
        if (!Y) {
            return
        }
        var X = new z(this, Y, W, m);
        K.push(X);
        return X
    }
    this.track = w;

    function U(Y, W, m, X) {
        if (X != this.nextuid) {
            return
        } else {
            return this.track(Y, W, m)
        }
    }
    this.trackWithUID = U;

    function l() {
        if ("" == this.nextuid || null == this.nextuid) {
            this.nextuid = I()
        }
        return this.nextuid
    }
    this.getNextUID = l;

    function I() {
        var m = Math.round((Math.random() * 10000000000)).toString(36);
        var W = new Date().getTime().toString(36);
        return W + m
    }

    function A() {
        setInterval(n, f);
        setInterval(u, p)
    }

    function b() {
        this.nextuid = "";
        for (var m = 0; m < K.length; ++m) {
            try {
                K[m].stop()
            } catch (W) {
                G(W)
            }
        }
    }
    this.unload = b;

    function n(X) {
        for (var m = 0; m < K.length; ++m) {
            try {
                K[m].update(X)
            } catch (W) {
                G(W)
            }
        }
    }
    this.doUpdate = n;

    function u(X) {
        for (var m = 0; m < K.length; ++m) {
            try {
                K[m].sync()
            } catch (W) {
                G(W)
            }
        }
    }
    this.doSync = u;

    function G(m) {
        H("", m)
    }
    this.error = G;

    function x(W, m) {
        return y([
            [W, m]
        ])
    }

    function J(m) {
        if (m && m.site !== undefined) {
            return m.site
        }
        return V
    }

    function B(m) {}
    this.debug = B;

    function o() {
        if (a.pageContext.getWindowLocationURL() === undefined) {
            return "http://"
        }
        if ("https" === a.pageContext.getWindowLocationURL().slice(0, 5)) {
            return "https://ssl-"
        } else {
            return "http://"
        }
    }

    function N() {
        if (!a.nlso) {
            try {
                var m = "";
                var X = "i00";
                if (a.pageContext.getUniqueId !== undefined) {
                    X = s
                }
                m = a.pageContext.getLocalStorageItem(X);
                if (m) {
                    return "&c=" + m
                } else {
                    var W = "0000",
                        Z = W + Math.ceil((new Date()).getTime() / 1000).toString(16) + (32768 | Math.random() * 65535).toString(16) + W;
                    a.pageContext.setLocalStorageItem(X, Z)
                }
            } catch (Y) {
                console.log("error" + Y)
            }
        }
        return ""
    }

    function H(X, W) {
        if (typeof(a.pageContext.tracking) != "undefined") {
            e = a.pageContext.tracking
        }
        if (e) {
            if (typeof(W.stream) == "undefined" || W.stream == "") {
                console.warn("Stream is mandatory for measurement");
                a.debug("Stream is mandatory for measurement")
            } else {
                var m = o() + J(W) + "." + s + "/j0=" + x(X, W) + "?lt=" + (new Date()).getTime().toString(36) + N();
                a.pageContext.preloadImage(m);
                a.debug(m)
            }
        }
    }
    this.send = H;

    function y(Y) {
        var W;
        var Z;
        var X;
        var m;
        var aa = /[+&,;=~!*'()]/g;
        var ab;
        switch (typeof Y) {
            case "string":
                return aa.test(Y) ? encodeURIComponent(Y).replace(aa, function(ac) {
                    var ad = R[ac];
                    if (ad) {
                        return ad
                    }
                    return ac
                }) : encodeURIComponent(Y);
            case "number":
                return isFinite(Y) ? Y.toString() : "null";
            case "boolean":
            case "null":
                return Y.toString();
            case "object":
                if (!Y) {
                    return "null"
                }
                W = [];
                if (typeof Y.length === "number" && !(Y.propertyIsEnumerable("length"))) {
                    m = Y.length;
                    for (Z = 0; Z < m; Z += 1) {
                        W.push(y(Y[Z]) || "null")
                    }
                    return "," + W.join("+") + ";"
                }
                for (X in Y) {
                    if (typeof X === "string") {
                        if (X !== "site") {
                            ab = y(Y[X]);
                            if (ab) {
                                W.push(y(X) + "=" + ab)
                            }
                        }
                    }
                }
                return "," + W.join("+") + ";";
            default:
        }
        return ""
    }

    function z(au, aq, ar, av) {
        function at() {
            var aB = Math.round((Math.random() * 10000000000)).toString(36);
            var aC = new Date().getTime().toString(36);
            return aC + aB
        }
        var an = "";
        an = au.getNextUID();
        au.nextuid = null;
        if (an == "") {
            an = at()
        }
        var ab = Math.round(new Date().getTime() / 1000);
        var ai;
        var az;
        var al;
        var ag;
        var ac = [0, 0, ab.toString(36)];
        var ad = 0;
        var aA = 0;
        var ae = true;
        var Y = false;
        var am;
        var aa;
        var X;
        var m;
        var ay = 0;
        ai = au;
        az = aq;
        if (av) {
            am = av
        } else {
            am = ai.HTML5Adapter
        }
        ag = ao(ar);
        al = new Array();
        al.push(ac);
        m = 0;
        aa = au.syncrate;
        X = au.getSyncRates();
        ak();

        function aw() {
            return an
        }

        function ao(aD) {
            var aB = new Object();
            for (var aC in aD) {
                aB[aC] = aD[aC]
            }
            return aB
        }

        function ax(aB) {
            if (al.length < ai.maxstates) {
                al.push(aB)
            }
        }

        function Z(aB) {
            ac = [aB, aB, Math.round(new Date().getTime() / 1000).toString(36)];
            ax(ac);
            ae = true;
            ad = 0
        }

        function ak() {
            if (X != null) {
                if (m < X.length) {
                    aa = X[m++];
                    ai.debug("switch syncrate to " + aa)
                }
            }
        }

        function aj(aB) {
            setTimeout(function() {
                if (aB == Math.round(am.getPosition(az)) || aB + 1 == Math.round(am.getPosition(az))) {
                    ay++;
                    if (ay >= 5) {
                        ay = 0;
                        if (aB - ac[1] > 1 || ac[1] - aB > 1) {
                            Z(aB)
                        }
                    }
                } else {
                    ay = 0
                }
            }, 1000)
        }

        function af(aE) {
            if (Y) {
                return
            }
            var aB = ac[1];
            var aC = aB;
            try {
                aC = Math.round(am.getPosition(az))
            } catch (aF) {}
            try {
                if (aB === aC) {
                    if (ad >= 0) {
                        ad++;
                        if (ad === ai.pausesync) {
                            ae = true
                        }
                    }
                    return
                }
                if (ad >= ai.pausesync) {
                    Z(aC)
                } else {
                    if (aB < aC - 1 || aB > aC) {
                        aj(aC)
                    } else {
                        ac[1] = aC;
                        if (ac[1] - ac[0] >= aa && aC - aA >= aa) {
                            ae = true;
                            ak()
                        }
                        ad = 0
                    }
                }
            } catch (aD) {
                G = true;
                ai.error(aD)
            }
        }
        this.update = af;

        function ap() {
            if (Y) {
                return
            }
            ae = true;
            Y = true;
            ah(null)
        }
        this.stop = ap;

        function ah() {
            if (a.pageContext.getSerialNumber !== undefined) {
                a.pageContext.getSerialNumber(W)
            } else {
                W()
            }
        }
        this.sync = ah;

        function W() {
            if (ae) {
                try {
                    if (az.width) {
                        ag.sx = az.width
                    }
                    if (az.videoWidth) {
                        ag.sx = az.videoWidth
                    }
                    if (az.height) {
                        ag.sy = az.height
                    }
                    if (az.videoHeight) {
                        ag.sy = az.videoHeight
                    }
                } catch (aC) {}
                if (a.pageContext.getUniqueId !== undefined) {
                    ag.psid = a.pageContext.getUniqueId()
                }
                ag.uid = an;
                ag.pst = al;
                var aB;
                try {
                    if (!ag.dur || ag.dur === 0) {
                        ag.dur = am.getDuration(az)
                    }
                } catch (aC) {}
                try {
                    aB = am.getMeta(az);
                    if (a.pageContext.getDeviceID !== undefined && a.pageContext.getDeviceID() !== undefined) {
                        aB[a.pageContext.getDeviceID()["id_name"]] = a.pageContext.getDeviceID()["id_value"]
                    }
                } catch (aC) {}
                ag.vt = (Math.round(new Date().getTime() / 1000)) - ab;
                aB.v = "js " + a.version;
                if (typeof(PageContext) !== "undefined" && typeof(PageContext.prototype.addElectronParameters) !== "undefined") {
                    PageContext.prototype.addElectronParameters(ag);
                    aB.v = "E " + a.version
                }
                if (am.isCasting && am.isCasting() == true) {
                    aB.v = "C " + a.version
                }
                ai.send(aB, ag);
                aA = ac[1]
            }
            ae = false
        }
    }
    this.defaultPageContext = {
        getLocalStorageItem: i,
        setLocalStorageItem: c,
        preloadImage: C,
        getWindowLocationURL: d,
    };
    this.HTML5Adapter = {
        getMeta: E,
        getPosition: t,
        getDuration: r
    };
    this.DefaultAdapter = {
        getMeta: k,
        getPosition: P,
        getDuration: Q
    };
    this.WMStreamAdapter = {
        getMeta: v,
        getPosition: j,
        getDuration: h
    };
    this.RVStreamAdapter = {
        getMeta: T,
        getPosition: O,
        getDuration: M
    };

    function E(m) {
        return {
            pl: "HTML 5 Video",
            plv: "HTML 5",
            sx: screen.width,
            sy: screen.height
        }
    }

    function r(m) {
        return Math.round(m.duration)
    }

    function t(m) {
        return m.currentTime
    }

    function k(m) {
        return {
            pl: "DEF",
            plv: version,
            sx: screen.width,
            sy: screen.height
        }
    }

    function P(m) {
        return new Date().getTime() / 1000
    }

    function Q(m) {
        return 0
    }

    function v(m) {
        return {
            pl: "MSWM",
            plv: m.versionInfo,
            sx: screen.width,
            sy: screen.height
        }
    }

    function j(m) {
        if (m.CurrentPosition) {
            return m.CurrentPosition
        }
        if (m.currentPosition) {
            return m.currentPosition
        }
        if (m.controls && m.controls.currentPosition) {
            return m.controls.currentPosition
        }
        if (m.Controls && m.Controls.currentPosition) {
            return m.Controls.currentPosition
        }
        return 0
    }

    function h(m) {
        if (m.currentMedia) {
            if (m.currentMedia.Duration) {
                return m.currentMedia.Duration
            }
            if (m.currentMedia.duration) {
                return m.currentMedia.duration
            }
        } else {
            if (m.CurrentMedia) {
                if (m.CurrentMedia.duration) {
                    return m.CurrentMedia.duration
                }
                if (m.CurrentMedia.Duration) {
                    return m.CurrentMedia.Duration
                }
            }
        }
    }

    function T(m) {
        return {
            pl: "RV",
            plv: m.GetVersionInfo(),
            sx: screen.width,
            sy: screen.height
        }
    }

    function O(m) {
        return (m.GetPosition() / 1000)
    }

    function M(m) {
        return (m.GetLength() / 1000)
    }

    function c(m, W) {
        localStorage.setItem(m, W)
    }

    function i(m) {
        return localStorage.getItem(m)
    }

    function C(m) {
        (new Image()).src = m
    }

    function d() {
        return document.location.href
    }
}
SpringStreams.prototype.setPageContext = function(a) {
    this.pageContext = a
};
/** START BBC ADDITION **/
return SpringStreams;
});
/** END BBC ADDITION **/
