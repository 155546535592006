/**
 * Exports the EchoClient class, used for sending usage events
 * @exports Echo/EchoClient
 */
define(function (require) {
  'use strict';

  var DEBUG = require('./util/debug');
  var Util = require('./util/methods');

  // some conditions that are illegal from echo's viewpoint (e.g. seek after end event)
  // are considereed legal from the player's viewpoint
  // setting the flag to false will throw an error instead of logging for these cases
  // https://jira.dev.bbc.co.uk/browse/MYSTATS-2863
  var FailSilently = true;

  var ConfigKeys = require('./config/keys');
  var Destinations = require('./config/destinations');
  var RemoteConfigManager = require('./remote/remote-config-manager');
  var Masterbrands = require('./config/masterbrands');
  var Producers = require('./config/producers');
  var NationsProducer = require('./config/nations-producer');
  var Enums = require('./enumerations');
  var LiveBroker = require('./live-broker');
  var OnDemandBroker = require('./on-demand-broker');
  var CSKeys = require('./delegate/comscore/label-keys');
  var Schedule = require('./schedule');
  var CookieHelper = require('./util/cookies');

  var scoreCardHostname = 'scorecardresearch.com';
  var oldEchoDeviceIdCookieName = 'ckpf_echo_device_id';
  var echoDeviceIdCookieName = 'ckns_echo_device_id';

  var OrbitVariables = require('./delegate/ati/orbit-variables');
  var Promise = require('es6-promise').Promise;
  var HOUR_MILISECONDS = 3600000;

  /**
   * Initialise an EchoClient object
   * @constructor
   * @param {string} appName Application Name
   * @param {string} appType Application Type (one of Enums.AplicationType)
   * @param {object} [config] config key-value pairs to override default config
   * @param {Environment} [env] Environment instance
   *
   * @example
   * var echo = new EchoClient('MyApp',ApplicationType.WEB,"abcdeffedcba");
   */
  function EchoClient(appName, appType, config, env, callback) {
    var self = this;

    config = config || {};

    Util.assertContainsValue(Enums.ApplicationType, appType,
      'appType should be one of Enums.ApplicationType, got "' +
      appType + '"');

    // Create space to hold state for the Application
    this.state = {
      counterNameSet: false
    };

    var c = EchoClient.ConfigGenerator.generate(config);

    this._env = env !== undefined && env !== null ? env : new EchoClient.Environment();

    setInterval(function() {
      self._remoteConfigManager = new RemoteConfigManager(config, self._env);
    }, HOUR_MILISECONDS);

    this._remoteConfigManager = new RemoteConfigManager(config, this._env);

    if (c) {
      c[ConfigKeys.REMOTE_CONFIG_MANAGER] = this._remoteConfigManager;
    }

    // set deviceid and clean of any whitespace
    var deviceId;
    if (c && typeof c[ConfigKeys.ECHO_DEVICE_ID] === 'string') {
      deviceId = Util.trim(c[ConfigKeys.ECHO_DEVICE_ID]);
      if (deviceId.length > 0) {
        c[ConfigKeys.ECHO_DEVICE_ID] = deviceId;
        this._deviceId  = deviceId;
      } else {
        c[ConfigKeys.ECHO_DEVICE_ID] = undefined;
      }
    }

    // if reporting is not scorecardresearch.com and the ckpf_echo_device_id cookie is present
    // use it as the device ID to override the s1 cookie. See MYSTATS-2684
    if (c && c[ConfigKeys.COMSCORE_HOST] !== scoreCardHostname && !c[ConfigKeys.ECHO_DEVICE_ID]) {
      deviceId = CookieHelper.getCookieValueByName(echoDeviceIdCookieName);
      if (!deviceId) {
        deviceId = CookieHelper.getCookieValueByName(oldEchoDeviceIdCookieName);
      }

      if (deviceId) {
        this._deviceId = deviceId;
        c[ConfigKeys.ECHO_DEVICE_ID] = deviceId;
      }
    }

    if (c && c[ConfigKeys.ECHO_AUTO_START] === false) {
      this._autoStart = false;
    } else {
      this._autoStart = true;
    }

    // Create set of 'consumers'
    this._setConsumers(appName, appType, c, this._env);

    if (c && c[ConfigKeys.USE_ESS]) {
      this._essEnabled = true;
    } else {
      this._essEnabled = false;
    }

    if (c && c[ConfigKeys.ESS_HOSTNAME]) {
      Schedule.essHost = c[ConfigKeys.ESS_HOSTNAME];
    }

    if (c && c[ConfigKeys.ECHO_ENABLED] === false) {
      this._isEnabled = false;
    } else {
      this._isEnabled = true;
    }

    if (c) {
      switch (c[ConfigKeys.DEBUG_MODE]) {
        case true:
          DEBUG.enable();
          break;
        case 'error':
          DEBUG.enable();
          DEBUG.setLevel(DEBUG.DebugLevels.ERROR);
          break;
        case 'warn':
          DEBUG.enable();
          DEBUG.setLevel(DEBUG.DebugLevels.WARN);
          break;
        case 'info':
          DEBUG.enable();
          DEBUG.setLevel(DEBUG.DebugLevels.INFO);
          break;
        default:
          DEBUG.disable();
          break;
      }
    }

    this._liveBroker = null;
    this._onDemandBroker = null;

    this._suppressingPlayEvent = false;
    this._suppressedPlayEventLabels = null;

    this.addLabel('bbc_id_wait', '1');
    OrbitVariables.getUser().then(function(userVariables) {
      this.removeLabel('bbc_id_wait');
      if (!userVariables || !userVariables.isSignedIn) {
        return;
      }

      var hid;
      if (userVariables.hashedId) {
        hid = encodeURIComponent(userVariables.hashedId);
      } else {
        hid = 'unidentified-user';
      }

      this.addLabels({
        // the old call to orbIdCta.getIStatsLabels().bbc_identity would return 1 for signed in
        // or 0 for signed out, we only add labels when signed in now so this is for backwards compatibility
        bbc_identity: '1',
        bbc_hid: hid
      });

    }.bind(this));

    if (c && c[ConfigKeys.ECHO_CACHE_MODE]) {
      this._cacheMode = c[ConfigKeys.ECHO_CACHE_MODE];
    }

    this._atiDisableCookie = !!c && !!c[ConfigKeys.ATI_DISABLE_COOKIE];

    if (this._isEnabled && this._autoStart) {
      if (typeof callback === 'function') {
        this.start().then(callback);
      } else {
        this.start();
      }
    }
  }

  EchoClient.prototype.getDebugMode = function() {
    return DEBUG.getState();
  };

  EchoClient.prototype.start = function() {
    var orbitVariables;
    if (this._isEnabled && !this._hasStarted) {
      this._hasStarted = true;
      return OrbitVariables.get().then(function (orbitVars) {
        orbitVariables = orbitVars;
        this._delegate('start', [orbitVars]);
      }.bind(this))
      .then(function () {
        if (orbitVariables &&
            orbitVariables.pageName &&
            orbitVariables.pageName !== '' &&
            orbitVariables.pageName !== 'orb.page') {
          this.setCounterName(orbitVariables.pageName);
        }
      }.bind(this), function (e) {
        DEBUG.error('failed to start delegates: ' + e);
      });
    } else {
      return Promise.reject();
    }
  };

  EchoClient.prototype.enable = function() {
    if (!this._isEnabled) {
      this._isEnabled = true;
      this._delegate('enable');

      if (this._autoStart && !this._hasStarted) {
        this.start();
      }
    }

    return this;
  };

  EchoClient.prototype.disable = function() {
    if (this._isEnabled) {
      this._clearMedia();
      this._isEnabled = false;
      this._delegate('disable');
    }

    return this;
  };

  EchoClient.prototype.isEnabled = function() {
    return this._isEnabled;
  };

  /**
   * @return {String|null}
   */
  EchoClient.prototype.getComScoreDeviceId = function() {
    var delegate;

    if (this.consumers[EchoClient.Consumers.COMSCORE]) {
      delegate = this.consumers[EchoClient.Consumers.COMSCORE];
      if (
        delegate instanceof EchoClient.ComScoreDelegate &&
        typeof delegate.getDeviceId === 'function'
      ) {
        return delegate.getDeviceId();
      }
    }

    return null;
  };

  /**
   * @return {String|null}
   */
  EchoClient.prototype.getAtiDeviceId = function() {
    var delegate;

    if (this.consumers[EchoClient.Consumers.ATI]) {
      delegate = this.consumers[EchoClient.Consumers.ATI];
      if (
        delegate instanceof EchoClient.AtiDelegate &&
        typeof delegate.getDeviceId === 'function'
      ) {
        return delegate.getDeviceId();
      }
    }

    return null;
  };

  /**
   * Get the current device id the client is configured with.
   * @return {String|undefined}
   */
  EchoClient.prototype.getDeviceId = function() {
    return this._deviceId;
  };

  /**
   * Update the current device id.
   */
  EchoClient.prototype.setDeviceId = function(deviceId) {
    this._deviceId = deviceId;
    this._delegate('setDeviceId', [deviceId]);
  };

  /**
   * Set the trace identifier. This can then be used to retrieve the events
   * under test.
   *
   * @param {String} id
   */
  EchoClient.prototype.setTraceId = function(id) {
    this._delegate('setTraceId', [id]);
  };

  // Dependencies, saved here so we can mock them
  EchoClient.ConfigGenerator = require('./config/generator');
  EchoClient.LabelCleanser = require('./util/cleansing/label-cleanser');
  EchoClient.Util = require('./util/methods');
  EchoClient.ComScoreDelegate = require(
    './delegate/comscore/comscore-delegate'
  );
  EchoClient.SpringDelegate = require('./delegate/spring/spring-delegate');
  EchoClient.AtiDelegate = require('./delegate/ati/ati-delegate');
  EchoClient.Environment = require('./environment');

  /* *
   * --------------------------------------------------------------------
   * Private Methods
   * --------------------------------------------------------------------
   * */

  EchoClient.prototype._eventsEnabled = function() {
    return this._isEnabled && this._hasStarted;
  };

  EchoClient.prototype._setConsumers = function (appName, appType, conf, env) {
    this.consumers = {};
    var cleanAppName = EchoClient.LabelCleanser.cleanLabelValue(
      'app_name', appName
    );

    if (conf && conf[ConfigKeys.COMSCORE_ENABLED]) {
      this.consumers[EchoClient.Consumers.COMSCORE] =
        new EchoClient.ComScoreDelegate(cleanAppName, appType, conf, env);
    }

    if (conf && conf[ConfigKeys.ATI_ENABLED]) {
      this.consumers[EchoClient.Consumers.ATI] =
        new EchoClient.AtiDelegate(cleanAppName, appType, conf, env);
    }

    if (conf && conf[ConfigKeys.BARB_ENABLED]) {
      this.consumers[EchoClient.Consumers.BARB] =
        new EchoClient.SpringDelegate(cleanAppName, appType, conf, env);
    }

    return this;
  };

  /**
   * We should reset the device id after log out for privacy reasons.
   * However we should only clear it if the ATI cookie is disabled, because
   * if the ATI cookie is enabled, it will be deleted by BBC account.
   */
  EchoClient.prototype._shouldClearDeviceIdOnLogOut = function() {
    return this._atiDisableCookie;
  };

  /**
   * Call the specified function on all objects in this.consumers
   * @private
   * @param {String} fName Name of the function
   * @param {Array} args Arguments
   * @param {Array} cleanArgs Cleansed arguments to be used if the consumer requires label cleansing
   */
  EchoClient.prototype._delegate = function(fName, args, cleanArgs) {
    var index;
    var consumer;
    var promises = [];

    for (index in this.consumers) {
      if (index) {
        consumer = this.consumers[index];

        if (cleanArgs && consumer.requiresLabelCleansing()) {
          promises.push(consumer[fName].apply(consumer, cleanArgs));
        } else {
          promises.push(consumer[fName].apply(consumer, args));
        }
      }
    }

    return Promise.all(promises);
  };

  /* *
   * --------------------------------------------------------------------
   * Application state Methods
   * --------------------------------------------------------------------
   * */

  /**
   * NOTE: method now delegates to addProperties method
   */
  EchoClient.prototype.addLabels = function(labels) {
    return this.addProperties(labels);
  };

  /**
   * NOTE: method now delegates to addProperty method
   */
  EchoClient.prototype.addLabel = function(key, value) {
    return this.addProperty(key, value);
  };

  /**
   * Add properties to be sent with every event. Multiple calls to
   * this function will append properties to the current list.
   * Property keys must only contain the following chars : [a-z0-9_-.]
   * Property values must be a string or number
   * @param {object} properties Key-value pairs
   * @returns {EchoClient} `this`
   * @example
   * // set a property
   * echo.addProperties({bun:'cinnamon'});
   * // Update it and set another
   * echo.addProperties({bun:'sticky',princess:'hotdog'});
   */
  EchoClient.prototype.addProperties = function(properties) {
    properties = EchoClient.LabelCleanser.removeUndefined(properties);
    this._delegate('addProperties', [EchoClient.LabelCleanser.cleanLabels(properties)]);
    return this;
  };

  /**
   * Add campaign properties to be sent with every event.
   * The CHANNEL, MEDIUM, and CAMPAIGN propeties must be provided
   * @param {object} properties campaign key-value pairs
   * @returns {EchoClient} `this`
   * @example
   * var campaignProperties = {};
   * campaignProperties[Enums.EchoLabelKeys.CHANNEL] = 'channel';
   * campaignProperties[Enums.EchoLabelKeys.MEDIUM] = 'medium';
   * campaignProperties[Enums.EchoLabelKeys.CAMPAIGN] = 'campaign';
   * campaignProperties[Enums.EchoLabelKeys.CAMPAIGN_GROUP] = 'campaign_group';
   * campaignProperties[Enums.EchoLabelKeys.VENDOR] = 'vendor';
   * campaignProperties[Enums.EchoLabelKeys.CREATION] = 'creation';
   * campaignProperties[Enums.EchoLabelKeys.AFFILIATE_TYPE] = 'aff_type';
   * echo.addCampaignProperties(campaignProperties);
   */
  EchoClient.prototype.addCampaignProperties = function(properties) {
    properties = EchoClient.LabelCleanser.removeUndefined(properties);

    if (!properties.hasOwnProperty('src_channel') || !properties.hasOwnProperty('src_medium') || !properties.hasOwnProperty('src_campaign')) {
      DEBUG.error('Campaign properties must contain channel, medium, and campaign');
      return this;
    }

    this._delegate('addCampaignProperties', [properties]);
    return this;
  };

  /**
   * Add a single property
   * @param {string} key
   * @param {string|int} value
   * @returns {EchoClient} `this`
   */
  EchoClient.prototype.addProperty = function(key, value) {
    if (typeof key !== 'string') {
      DEBUG.error('Property key must be a string');
      return;
    }

    if (value === null || typeof value === 'undefined') {
      DEBUG.error('Property cannot be null/undefined');
      return;
    }

    var properties = {};
    properties[key] = value;

    return this.addProperties(properties);
  };

  /**
   * Add a single label that is known by Echo
   * @param {string} key
   * @param {string|int} value
   * @returns {this} `this`
   */
  EchoClient.prototype.addManagedLabel = function(key, value) {
    this._delegate('addManagedLabel', [
      EchoClient.LabelCleanser.cleanLabelKey(key),
      EchoClient.LabelCleanser.cleanLabelValue(key, value)
    ]);

    return this;
  };

  /**
    * NOTE: method now delegates to removeProperties method
    */
  EchoClient.prototype.removeLabels = function(labels) {
    return this.removeProperties(labels);
  };

  /**
   * NOTE: method now delegates to removeProperty method
   */
  EchoClient.prototype.removeLabel = function(label) {
    return this.removeProperty(label);
  };

  /**
   * Remove the specified properties
   * @param {array} properties A list of property names (keys) that need removing
   * @returns {this} `this`
   */
  EchoClient.prototype.removeProperties = function(properties) {
    var cleanProperties = [];
    for (var i = 0, j = properties.length; i < j; i++) {
      cleanProperties.push(EchoClient.LabelCleanser.cleanLabelKey(properties[i]));
    }

    this._delegate('removeProperties', [cleanProperties]);

    return this;
  };

  /**
   * Remove a single property
   * @param {string} property
   * @returns {this} `this`
   */
  EchoClient.prototype.removeProperty = function(property) {
    return this.removeProperties([property]);
  };

  /**
   * Set whether a product user as logged into the bbc
   * @param {string} hid
   * @returns {this} `this`
   */
  EchoClient.prototype.setLoggedInToBBCId = function(hid) {
    this._delegate('setLoggedInToBBCId', [hid]);
    return this;
  };

  /**
   * Set whether a product user is logged into the bbc or not
   * @returns {this} `this`
   */
  EchoClient.prototype.setLoggedOutOfBBCId = function() {
    this._delegate('setLoggedOutOfBBCId');

    if (this._shouldClearDeviceIdOnLogOut()) {
      DEBUG.info('Clearing device id on logout');
      this.setDeviceId(undefined);
    }

    return this;
  };

  /**
   * Set the version of the application (this is optional)
   * @param {string} version The version string for the application
   * @returns {this} `this`
   */
  EchoClient.prototype.setAppVersion = function(version) {
    this._delegate('setAppVersion', [version]);
    return this;
  };

  /**
   * Sets the destination
   * @param {string} code The destination code as a string e.g. 'BBC_THREE'
   * @returns {this} `this`
   */
  EchoClient.prototype.setDestination = function(code) {
    this._delegate('setDestination', [Destinations.get(code)]);
    return this;
  };

  /**
   * Sets the producer
   * @param {string} code The producer code as a string e.g. 'WALES'
   * @returns {this} Result of calling the private _setProducer method
   */
  EchoClient.prototype.setProducer = function(code) {
    return this._setProducer(code, false);
  };

  /**
   * Sets the nations producer
   * When provided with null, empty string or NationsProducer.NONE the nations producer property will be removed
   * @param {string} nationsProducerName The nations producer name as a string e.g. 'WALES'
   * @returns {this}
   */
  EchoClient.prototype.setNationsProducer = function(nationsProducerName) {
    // Retrieve nations producer based on input given, if NationsProducer.NONE provided translate to empty string
    var nationsProducer = NationsProducer.getName(nationsProducerName);
    if (nationsProducer === undefined) {
      DEBUG.info('Setting nations producer failed: nations producer ' + nationsProducerName + ' is not valid');
      return;
    }

    if (nationsProducer && nationsProducer !== NationsProducer.NONE) {
      // If valid, set Nations Producer
      this.addProperty(Enums.EchoLabelKeys.NATIONS_PRODUCER, nationsProducer);
    } else {
      this.removeProperty(Enums.EchoLabelKeys.NATIONS_PRODUCER);
    }
  };

  /**
   * Sets the producer using the PIP masterbrand code
   * @param {string} code The PIP masterbrand code as a string e.g. 'BBC_CYMRU'
   * @returns {this} Result of calling the private _setProducer method
   */
  EchoClient.prototype.setProducerByMasterbrand = function(code) {
    return this._setProducer(code, true);
  };

  /**
   * Private method that sets the producer based on the producer or PIP masterbrand code
   * @param {string} code The producer or PIP masterbrand code as a string
   * @param {boolean} isMasterbrand Flag to indicate where to look up the code
   * @returns {this} `this`
   */
  EchoClient.prototype._setProducer = function(code, isMasterbrand) {
    var producerId;
    var remoteProducers;
    var remoteMasterbrands;

    remoteProducers = this._remoteConfigManager.getProducers();
    remoteMasterbrands = this._remoteConfigManager.getMasterbrands();

    if (code && code.toUpperCase() !== 'NULL') {
      if (isMasterbrand) {
        // Retrieve data from remote if it exists, otherwise fall back to local enum
        var remoteMasterbrandAvailable = (remoteProducers && remoteMasterbrands && remoteMasterbrands[code] && remoteProducers[remoteMasterbrands[code]]);
        producerId = remoteMasterbrandAvailable ? remoteProducers[remoteMasterbrands[code]] : Masterbrands.getProducerId(code);
      } else {
        producerId = (remoteProducers && remoteProducers[code]) ? remoteProducers[code] : Producers.getId(code);
      }
    } else {
      // The producer ID should be set to 0 so that it is removed by ATI
      producerId = '0';
    }

    if (!producerId) {
      // Log a message as the code was not recognised
      var lookupEntityDescription = isMasterbrand ? 'Masterbrand' : 'Producer';
      DEBUG.warn(lookupEntityDescription + ' [' + code + '] not recognised');
    }

    if (producerId) {
      this._delegate('setProducer', [producerId]);
    }

    return this;
  };

  /**
   * Set the countername for this page, this can also be set when
   * a viewEvent is sent. This method provides a way of setting the
   * countername without sending a view event.
   *
   * @param {string} countername The countername
   *
   * @returns {this} `this`
   */
  EchoClient.prototype.setCounterName = function(countername) {
    var cleanCounterName = EchoClient.LabelCleanser.cleanCounterName(countername);

    var returnValue = this._delegate('setCounterName', [countername], [cleanCounterName]);
    this.state.counterNameSet = true;

    return returnValue;
  };

  /**
   * Set the language of the content being displayed, as opposed to
   * the locale language (which can be set on the Environment object)
   * the format for the tag still needs guidance from M&A, though I suspect
   * that following these
   * [instructions](http://www.w3.org/International/questions/qa-choosing-language-tags)
   * will work out fine
   * @param {string} language The language (code) of the content
   * @returns {this} `this`
   */
  EchoClient.prototype.setContentLanguage = function(language) {
    this._delegate('setContentLanguage', [language]);
    return this;
  };

  /**
   * Sets the cache mode for offline-enabled apps
   * @param {string} mode The cache mode
   * @returns {this} `this`
   */
  EchoClient.prototype.setCacheMode = function(mode) {
    this._delegate('setCacheMode', [mode]);
    this._cacheMode = mode;
    return this;
  };

  EchoClient.prototype.getCacheMode = function() {
    return this._cacheMode;
  };

  EchoClient.prototype.clearCache = function() {
    if (!this._isEnabled) {
      return;
    }

    this._delegate('clearCache');
  };

  EchoClient.prototype.flushCache = function() {
    if (!this._isEnabled) {
      return;
    }

    this._delegate('flushCache');
  };

  /* *
   * ------------------------------------------------------------------------
   * Basic Analytics Methods
   * ------------------------------------------------------------------------
   * */

  /**
   * Register a 'view event'. This indicates a new 'page' has been displayed.
   * See
   * [here](https://confluence.dev.bbc.co.uk/display/echo/Echo+Client+for+Product+Managers#EchoClientforProductManagers-Counternames)
   * for guidance on setting the countername. The '.page' suffix will be added
   * automatically if not provided.
   *
   * @param {string} countername The countername for this page
   * @param {object} eventLabels
   * @returns {this} `this`
   * @example
   * echo.viewEvent('news.scotland.page',{label1Key:'label1Value'});
   */
  EchoClient.prototype.viewEvent = function(countername, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    var cleanCounterName = EchoClient.LabelCleanser.cleanCounterName(countername);
    var cleanEventLabels = EchoClient.LabelCleanser.cleanLabels(eventLabels);

    // Note down that we have evented to comScore at least once
    if (this.consumers[EchoClient.Consumers.COMSCORE]) {
      this.state.comscoreEventSent = true;
    }

    var returnValue = this._delegate('viewEvent', [countername, eventLabels], [cleanCounterName, cleanEventLabels]);
    this.state.counterNameSet = true;

    if (this.consumers[EchoClient.Consumers.COMSCORE]) {
      this.state.comscoreEventSent = true;
    }

    return returnValue;
  };

  /**
   * Register a bespoke event.
   * See
   * [here](https://confluence.dev.bbc.co.uk/display/echo/Echo+Client+for+Product+Managers#EchoClientforProductManagers-Useractiontypesandnames)
   * for advice on setting the actionType and actionName values
   *
   * @param {string} actionType The type of the event (e.g. 'click')
   * @param {string} actionName A description of the event (e.g. 'Button Z')
   * @param {object} [eventLabels] Additional labels
   * @returns {this} `this`
   * @example
   * Register a UserAction event (sent to Analytics (ComScore) only)
   * echo.userActionEvent('click','massive button',{info:'somrthing'});
   * Register a UserAction event also
   * echo.userActionEvent('click','massive button',{info:'somrthing'},
   *  EchoClient.Routing.ANALYTICS | EchoClient.Routing.BBC);
   */
  EchoClient.prototype.userActionEvent = function(
    actionType,
    actionName,
    eventLabels
  ) {
    if (!this._eventsEnabled()) {
      return;
    }

    if (!this.state.counterNameSet) {
      DEBUG.error('userActionEvent not available before a call to ' +
        'viewEvent (to set counter name).');
      return this;
    }

    // By default only send this to analytics
    this._delegate('userActionEvent', [actionType, actionName,
      EchoClient.LabelCleanser.cleanLabels(eventLabels)
    ]);

    // Note down that we have evented to comScore at least once
    if (this.consumers[EchoClient.Consumers.COMSCORE]) {
      this.state.comscoreEventSent = true;
    }

    return this;
  };

  /**
   * Register an error event
   *
   * @param {Error} [error] An Error object (or any object with at
   *                        least "name" and "message" properties)
   * @param {object} [eventLabels] Labels to send with this event
   * @returns {this} `this`
   */
  EchoClient.prototype.errorEvent = function(error, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    this._delegate(
      'errorEvent', [error, EchoClient.LabelCleanser.cleanLabels(eventLabels)]
    );

    return this;
  };

  /* *
   * ------------------------------------------------------------------------
   * Media Player Attributes
   * ------------------------------------------------------------------------
   * */

  /**
   * Set the name of the AV Media Player being used
   * @param {string} name
   * @returns {this} `this`
   */
  EchoClient.prototype.setPlayerName = function(name) {
    Util.assert(typeof name === 'string' && name.length > 0,
      'setPlayerName: name must be string with length, got "' + name + '"');

    var cleanName = EchoClient.LabelCleanser.cleanLabelValue(
      'player_name', name);

    return this._delegate('setPlayerName', [name], [cleanName]);
  };

  /**
   * Set the version of the AV Media Player
   * @param {string} version The version string
   * @returns {this} `this`
   */
  EchoClient.prototype.setPlayerVersion = function(version) {
    Util.assert(typeof version === 'string' && version.length > 0,
      'setPlayerVersion: version must be string with length, got "' +
      version + '"');

    var cleanVersion = EchoClient.LabelCleanser.cleanLabelValue(
      'player_version', version);

    return this._delegate('setPlayerVersion', [version], [cleanVersion]);
  };

  /**
   * Specify if the player has popped out to a new window
   * *Note: Changing this value will not generate an event,
   * but will change the value sent with all subsequent AV events*
   * @param {boolean} isPopped
   * @returns {this} `this`
   */
  EchoClient.prototype.setPlayerIsPopped = function(isPopped) {
    Util.assert(
      typeof isPopped === 'boolean',
      'setPlayerIsPopped: isPopped must be boolean, got "' + isPopped + '"'
    );
    this._delegate('setPlayerIsPopped', [isPopped]);
    return this;
  };

  /**
   * Set the Media Player window state
   * *Note: Changing this value will not generate an event,
   * but will change the value sent with all subsequent AV events*
   * @param {string} state Should be one of Echo.WindowState, can be any string
   * @returns {this} `this`
   * @example
   * echo.setPlayerWindowState(Echo.WindowState.FULL);
   */
  EchoClient.prototype.setPlayerWindowState = function(state) {
    Util.assertContainsValue(Enums.WindowState, state,
      'The window state must be set as a member' +
      ' of Enums.WindowState, got "' + state + '"');
    this._delegate('setPlayerWindowState', [state]);
    return this;
  };

  /**
   * Set the volume
   * *Note: Changing this value will not generate an event,
   * but will change the value sent with all subsequent AV events*
   * @param {integer} volume Should be an integer in the range 0-100
   * @returns {this} `this`
   */
  EchoClient.prototype.setPlayerVolume = function(volume) {
    Util.assert(
      volume <= 100 && volume >= 0,
      'volume must be 0-100, got: ' + volume
    );

    if ((volume <= 100) && (volume >= 0)) {
      this._delegate(
        'setPlayerVolume', [volume]
      );
    }

    return this;
  };

  /**
   * Indicate whether subtitles are turned on/off
   * *Note: Changing this value will not generate an event,
   * but will change the value sent with all subsequent AV events*
   * @param {boolean} isSubtitled (true for subtitles on)
   * @returns {this} `this`
   */
  EchoClient.prototype.setPlayerIsSubtitled = function(isSubtitled) {
    Util.assert(
      typeof isSubtitled === 'boolean',
      'setPlayerIsSubtitled: isSubtitled must be a boolean, got "' +
      isSubtitled + '"'
    );

    this._delegate('setPlayerIsSubtitled', [isSubtitled]);
    return this;
  };

  /**
   * The player delegate is responsible for reporting the current state of the
   * player. You must implement the following methods.
   *
   * - getTimestamp {Function} that returns a number denoting the players
   * timestamp expressed in Unix Epoch and in milliseconds
   * - getPosition {Function} the current position that the player is at
   *
   * @param {Object} delegate
   * @return EchoClient
   */
  EchoClient.prototype.setPlayerDelegate = function(delegate) {
    delegate = delegate || {};

    // required functions
    var functions = ['getTimestamp', 'getPosition'];
    var methodName;
    var i;
    var valid;

    // failed to initialise the echo client as interface is not met
    var fail = false;

    // check that the `delegate` contains the correct functions and log to
    // console if in debug mode.
    for (i = 0; i < functions.length; i++) {
      methodName = functions[i];

      // check delegate has the method and that it is a function
      valid = (
        delegate.hasOwnProperty(methodName) &&
        typeof delegate[methodName] === 'function'
      );

      Util.assert(
        valid,
        'The player delegate must implement "' + methodName + '"'
      );

      if (!valid) {
        fail = true;
      }
    }

    if (!fail) {
      this._playerDelegate = delegate;
    }

    return this;
  };

  /* *
   * ------------------------------------------------------------------------
   * Media Attributes
   * ------------------------------------------------------------------------
   * */

  /**
   * Set details of the media which the player is about to play.
   *
   * **WARNING: Any updates made to the Media object after it is passed in to
   * Echo will NOT have any effect.**
   *
   * This method should be the first method called when the user requests a
   * new piece of content and must be called before the avEvent methods are
   * called. AV event messages will include all of the attributes of the media
   * object passed via (the most recent call to) this method.
   *
   * This method should only be called when the player is in a stopped state,
   * i.e. before a play event or after an end event.
   *
   * @param {Media} media A Media object defining the content being consumed.
   * @returns {this} `this`
   */
  EchoClient.prototype.setMedia = function(media) {
    var self = this;
    var interval;
    if (!this._eventsEnabled()) {
      return;
    }

    this._clearMedia();

    var clonedMedia = media.getClone();

    if (!this.state.counterNameSet) {
      DEBUG.error(
        'setMedia: Must have a countername set ' +
        'or view event sent first'
      );
      return;
    }

    this.media = clonedMedia;

    this.addLabel(CSKeys.ESS_ENABLED, this._essEnabled ? 'true' : 'false');

    var live = Enums.MediaConsumptionMode.LIVE;
    if (this.media.getMediaConsumptionMode() === live) {
      this._initLiveBroker();
    } else {
      this._initOnDemandBroker();
    }

    this.media.setRemoteProducers(this._remoteConfigManager.getProducers());
    this.media.setRemoteMasterbrands(this._remoteConfigManager.getMasterbrands());

    if (!interval) {
      interval = setInterval(function() {
        if (self.media) {
          self.media.setRemoteProducers(self._remoteConfigManager.getProducers());
          self.media.setRemoteMasterbrands(self._remoteConfigManager.getMasterbrands());
        }
      }, HOUR_MILISECONDS);
    }

    this._delegate('setMedia', [this.media]);
  };

  /**
   * Invoked from the LiveBroker when a broadcast has been detected.
   *
   * @param {Media} newMedia
   * @param {Number} newPosition the previous position of the player
   * @param {Number} oldPosition the new position
   */
  EchoClient.prototype.liveMediaUpdate = function(
    newMedia,
    newPosition,
    oldPosition
  ) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    if (!newMedia) {
      return;
    }

    this._suppressingPlayEvent = false;

    this.media = newMedia;
    this._delegate('liveMediaUpdate', [newMedia, newPosition, oldPosition]);
  };

  /**
   * Set the length of the media (in milliseconds).
   *
   * Media length can be specified on the Media object prior to passing it to
   * Echo if the length is known up front. This method is provided to allow
   * players which do not know the length of piece of content before it starts
   * playing to report the length once it becomes available.
   *
   * For on-demand content, this This method must be called within the first
   * 60 seconds of playback (if the length has not already been passed to Echo
   * via the Media object). Failure to do so will result in inaccurate
   * consumption statistics for the content. This method should be called at
   * most once for each new piece of media passed to Echo.
   *
   * This method is not expected to be called for live content as a length of
   * zero should have been specified on the Media object passed to Echo.
   *
   * @param {integer} length The length of the media in ms.
   * @returns {this} `this`
   */
  EchoClient.prototype.setMediaLength = function(length) {
    if (!this.media) {
      DEBUG.error('setMediaLength: Must call setMedia first');
      return this;
    } else if (!this.media.isOnDemand()) {
      DEBUG.error(
        'setMediaLength: Length should be set to zero prior to ' +
        'passing the media object to Echo for live media'
      );
      return this;
    }

    length = this._cleanPosition(length);

    if (typeof length !== 'number') {
      DEBUG.error(
        'setMediaLength: Length must be a positive number'
      );
      return this;
    }

    this._delegate('setMediaLength', [length]);
    this.media.setLength(length);

    return this;
  };

  /**
   * @deprecated Field is no longer used
   */
  EchoClient.prototype.setMediaBitrate = function() {
  };

  /**
   * @deprecated Field is no longer used
   */
  EchoClient.prototype.setMediaCodec = function() {
  };

  /**
   * @deprecated Field is no longer used
   */
  EchoClient.prototype.setMediaCDN = function() {
  };

  EchoClient.prototype.setEssSuccess = function(isSuccess) {
    this.addLabel(CSKeys.ESS_SUCCESS, isSuccess ? 'true' : 'false');
  };

  EchoClient.prototype.setEssError = function(error, code) {
    this.addLabel(CSKeys.ESS_ERROR, error.toString().toLowerCase());

    if (error.toString() === Enums.EssError.STATUS_CODE.toString()) {
      this.addLabel(CSKeys.ESS_STATUS_CODE, code);
    }

    this._delegate('liveEnrichmentFailed');
  };

  /* *
   * ------------------------------------------------------------------------
   * Media Events
   * ------------------------------------------------------------------------
   * */

  /**
   * Both content Id and AV Type must have been set before trying to use the
   * AV event methods.
   *
   * This method throws an exception in debug mode if both of these attributes
   * have not been set. It returns false in non-debug mode if both of these
   * attributes have not been set.
   *
   * @private
   * @returns {Boolean} True if the appropriate state is set up to use the av
   *         even methods. Returns false if state is not set up in non-debug
   *         mode or throws an exception in debug mode.
   */
  EchoClient.prototype._avEventsEnabled = function() {

    return (
      Util.assert(
        Boolean(this.media), 'setMedia() must be called prior to this method', FailSilently
      ) &&
      Util.assert(
        Boolean(this._playerDelegate),
        'setPlayerDelegate not called or not configured correctly', FailSilently
      )
    );
  };

  /**
   * Get the live broker.
   *
   * @public
   */
  EchoClient.prototype.getLiveBroker = function() {
    return this._liveBroker;
  };

  /**
   * Init the Live broker.
   *
   * @private
   */
  EchoClient.prototype._initLiveBroker = function() {
    if (!this._avEventsEnabled() ||
      this.media.getMediaConsumptionMode() !== Enums.MediaConsumptionMode.LIVE
    ) {
      return;
    }

    this._liveBroker = new LiveBroker(
      this._playerDelegate, this.media, this, this._env, this._essEnabled
    );
    this._delegate('setBroker', [this._liveBroker]);
  };

  /**
   * Get the On-demand broker.
   *
   * @public
   */
  EchoClient.prototype.getOnDemandBroker = function() {
    return this._onDemandBroker;
  };

  /**
   * Init the On-demand broker.
   *
   * @private
   */
  EchoClient.prototype._initOnDemandBroker = function() {
    if (!this._avEventsEnabled() ||
      this.media.getMediaConsumptionMode() === Enums.MediaConsumptionMode.LIVE
    ) {
      return;
    }

    this._onDemandBroker = new OnDemandBroker(
      this._playerDelegate,
      this.media,
      this
    );
    this._delegate('setBroker', [this._onDemandBroker]);
  };

  /**
   * Clean a position value.
   *
   * @param {Number|string} value The value to clean
   * @returns {integer|false}
   */
  EchoClient.prototype._cleanPosition = function(value) {

    if (value !== undefined && value !== null) {
      value = Math.floor(value);
    }

    if (value === undefined || isNaN(value) || !isFinite(value) || value < 0) {
      value = 0;
    }

    return value;
  };

  /**
   * Register an AV Play event
   *
   * @param {integer} position Position through the media in ms
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avPlayEvent = function(position, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    var isLive = this.media.isLive();

    this._isPlaying = true;

    if (isLive && this._liveBroker) {
      position = this._liveBroker.getPosition();
      this._liveBroker.start();
    } else if (!isLive && this._onDemandBroker) {
      position = this._cleanPosition(position);
      this._previousPlayPosition = position;

      if (this._positionExceedsMediaLength(position)) {
        return;
      }

      this._onDemandBroker.setPosition(position);
      this._onDemandBroker.start();
    }

    if (isLive && this.media.isEssEnriched() && this._suppressingPlayEvent) {
      this._suppressedPlayEventLabels = eventLabels;
    } else {
      this._delegate(
        'avPlayEvent',
        [position, EchoClient.LabelCleanser.cleanLabels(eventLabels)]
      );
      this.media.setBuffering(false);
      this.media.setPlaying(true);
    }

    if (this.consumers[EchoClient.Consumers.COMSCORE]) {
      this.state.comscoreEventSent = true;
    }

    return this;
  };

  /**
   * Perform common av event actions.
   *
   * @param {string}  methodToDelegate The method to delegate
   * @param {integer} position Position through the media in ms
   * @param {object}  eventLabels Optional object of labels
   * @param {integer} rate Optional rate for rwd/ffwd
   * @returns {this} `this`
   */
  EchoClient.prototype._avNavigationEvent = function(
    methodToDelegate, position, eventLabels, rate
  ) {

    if (!this._avEventsEnabled()) {
      return;
    }

    this._isPlaying = false;

    if (this.media.isLive()) {
      if (this._liveBroker) {
        this._liveBroker.stop();
        position = this._liveBroker.getPosition();
      }

      if (this.media.isEssEnriched() && !this._suppressingPlayEvent) {
        this._suppressingPlayEvent = true;
      }

    } else if (this._onDemandBroker) {
      position = this._cleanPosition(position);
      position = this._preventPositionExceedingMediaLength(position);
      this._onDemandBroker.stop();
    }

    if (methodToDelegate === 'avRewindEvent' || methodToDelegate === 'avFastForwardEvent') {
      this._delegate(
        methodToDelegate,
        [position, rate, EchoClient.LabelCleanser.cleanLabels(eventLabels)]
      );
    } else {
      this._delegate(
        methodToDelegate,
        [position, EchoClient.LabelCleanser.cleanLabels(eventLabels)]
      );
    }

  };

  /**
   * Register an AV Pause event
   *
   * @param {Number} position Position through the media in ms
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avPauseEvent = function(position, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    this.media.setPlaying(false);
    this._avNavigationEvent('avPauseEvent', position, eventLabels);

    return this;
  };

  /**
   * Register an AV Buffer event
   *
   * @param {integer} position Position through the media in ms
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avBufferEvent = function(position, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled() || !this.media.getPlaying()) {
      return;
    }

    this.media.setPlaying(false);
    this._avNavigationEvent('avBufferEvent', position, eventLabels);
    this.media.setBuffering(true);

    return this;
  };

  /**
   * Register an AV End event
   *
   * @param {integer} position Position through the media in ms
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avEndEvent = function(position, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    this.media.setPlaying(false);
    this._avNavigationEvent('avEndEvent', position, eventLabels);

    this.media = null;

    return this;
  };

  /**
   * Register an AV Rewind event
   *
   * @param {integer} position Position through the media in ms
   * @param {integer} rate The rate of the rewind (i.e. 2 = double-speed)
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avRewindEvent = function(position, rate, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    this._avNavigationEvent('avRewindEvent', position, eventLabels, rate);

    return this;
  };

  /**
   * Register an AV Fast Forward event
   *
   * @param {integer} position Position through the media in ms
   * @param {integer} rate The rate of the fast-forward (i.e. 2 = double-speed)
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avFastForwardEvent = function(
    position,
    rate,
    eventLabels
  ) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    this._avNavigationEvent('avFastForwardEvent', position, eventLabels, rate);

    return this;
  };

  /**
   * Register an AV Seek event
   *
   * @param {integer} position Position through the media in ms
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   */
  EchoClient.prototype.avSeekEvent = function(position, eventLabels) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    this._avNavigationEvent('avSeekEvent', position, eventLabels);

    return this;
  };

  /**
   * Register a custom AV event
   *
   * @param {string} actionType The type of action
   * @param {string} actionName The name of the action
   * @param {integer} position Position in media in ms (0 for simulcast)
   * @param {object} [eventLabels] Custom labels to set for this event
   * @returns {this} `this`
   * @example
   * echo.avUserActionEvent('click','av_related_button',5000);
   */
  EchoClient.prototype.avUserActionEvent = function(
    actionType,
    actionName,
    position,
    eventLabels
  ) {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    if (this._liveBroker) {
      position = this._liveBroker.getPosition();
    } else {
      position = this._cleanPosition(position);
      position = this._preventPositionExceedingMediaLength(position);
    }

    var cleanLabels = EchoClient.LabelCleanser.cleanLabels(eventLabels);
    this._delegate(
      'avUserActionEvent',
      [actionType, actionName, position, cleanLabels]
    );
    return this;
  };

  /**
   * Release a suppressed play event
   *
   * @returns {this} `this`
   */
  EchoClient.prototype.releaseSuppressedPlay = function() {
    if (!this._eventsEnabled() || !this._avEventsEnabled()) {
      return;
    }

    if (this._suppressingPlayEvent) {
      this._suppressingPlayEvent = false;
      this.avPlayEvent(this._liveBroker.getPosition(),
        this._suppressedPlayEventLabels);
    }

    return this;
  };

  /**
   *
   * @private
   */
  EchoClient.prototype._clearMedia = function() {
    this.removeLabel(CSKeys.MEDIA_TIMESTAMP);
    this.removeLabel(CSKeys.ESS_ENABLED);
    this.removeLabel(CSKeys.ESS_ENRICHED);
    this.removeLabel(CSKeys.ESS_SUCCESS);
    this.removeLabel(CSKeys.ESS_ERROR);
    this.removeLabel(CSKeys.ESS_STATUS_CODE);

    if (this.media !== null) {
      this.media = null;
    }

    if (this._liveBroker instanceof LiveBroker) {
      this._liveBroker.stop();
      this._liveBroker = null;
    }

    if (this._onDemandBroker instanceof OnDemandBroker) {
      this._onDemandBroker.stop();
      this._onDemandBroker = null;
    }

    this._isPlaying = undefined;
    this._previousPlayPosition = undefined;
  };

  /**
   *
   * @private
   */
  EchoClient.prototype._positionExceedsMediaLength = function(position) {

    if (this.media && this.media.length > 0 && position >= (this.media.length - 1000)) {
      return true;
    }

    return false;
  };

  /**
   *
   * @private
   */
  EchoClient.prototype._preventPositionExceedingMediaLength = function(position) {

    if (this._positionExceedsMediaLength(position)) {
      return this.media.length;
    }

    return position;
  };

  EchoClient.Consumers = { //NOTE: must be powers of 2
    COMSCORE: 1 << 0,
    BARB: 1 << 1,
    ATI: 1 << 2
  };

  return EchoClient;
});
