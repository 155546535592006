define(['./echo/bootstrap'], function(bootstrap) {
    'use strict';

    if (window && window.bbccookies) {
      bootstrap.Environment.setOrbCookies(window.bbccookies);
    }

    return bootstrap;
  }
);
